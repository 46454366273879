<template>
  <div id="expertise">
    <div class="pt-[50px] lg:pt-[150px] 2xl:px-60 flex-container">
      <div class="flex flex-wrap">
        <div class="mt-32 w-full pl-5 lg:w-1/2">
          <h1 class="mb-10 text-lg font-bold">
            Our
            <span class="text-primary">Expertise</span>
          </h1>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class="relative">
                <h1
                  class="text-bodas bg-second rounded-full py-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >
                  <img class="ml-1 h-[20px] lg:h-[30px] lg:mt-[10px]" src="../assets/Vector.png" />
                </h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Reputable Public Exposure</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >The work and assistance of Catra Research Institute are conducted in a transparent and accountable manner</h2>
              </div>
            </div>
          </div>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class="relative">
                <h1
                  class="text-bodas bg-second rounded-full py-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >
                  <img class="ml-1 h-[20px] lg:h-[30px] lg:mt-[10px]" src="../assets/Vector2.png" />
                </h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Strategic Partnership</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >We direct a good partnership-building in national and international research collaboration.</h2>
              </div>
            </div>
          </div>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class="relative">
                <h1
                  class="text-bodas bg-second rounded-full py-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >
                  <img class="ml-1 h-[20px] lg:h-[30px] lg:mt-[10px]" src="../assets/Vector3.png" />
                </h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Quality and Integration</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >Our journals are indexed nationally and internationally and we collaborate with national and international journal publishers.</h2>
              </div>
            </div>
          </div>

          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class="relative">
                <h1
                  class="text-bodas bg-second rounded-full py-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >
                  <img class="ml-1 h-[20px] lg:h-[30px] lg:mt-[10px]" src="../assets/Vector4.png" />
                </h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Competency Development</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >Catra Research Institute is managed and assisted by professionals and experienced expert team in research and scientific publication.</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full self-end px-4 lg:w-1/2">
          <div class="mt-10">
            <img src="../assets/ourExpertise.png" alt="catra" class="max-w-full mx-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import "@/assets/tailwind.css";

export default {};
</script>
    
    <style>
</style>