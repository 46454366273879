<template>
  <div id="editing">
    <div class="pt-[50px] lg:pt-[160px] 2xl:px-60 flex-container">
      <div class="text-center">
        <h1 class="text-lg font-bold text-hideung pb-10">
          EDITING
          <span class="text-primary">SERVICE PROCEDURES</span>
        </h1>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full self-center pl-10 2xl:pl-20 lg:w-1/4">
          <div
            class="transition duration-300 ease-in-out hover:bg-lemon block max-w-[350px] rounded-[20px] shadow-lg bg-bodas mb-10 mr-10 lg:mb-20 lg:w-[248px] lg:h-[300px]"
          >
            <div class="pt-14">
              <img src="../assets/articles.png" alt="catra" class="max-w-full mx-auto mb-6" />
              <h1 class="text-center mb-6 text-md font-medium leading-tight text-primary">Articles submission</h1>
              <h2
                class="mx-3 text-center text-sm"
              >Submit your manuscripts and document to email: info@journalkeberlanjutan.com and confirm via our WhatsApp number.</h2>
            </div>
          </div>
          <div
            class="transition duration-300 ease-in-out hover:bg-lemon block max-w-[350px] rounded-[20px] shadow-lg bg-bodas mr-10 lg:mb-20 lg:w-[248px] lg:h-[300px]"
          >
            <div class="pt-14">
              <img src="../assets/analyzing.png" alt="catra" class="max-w-full mx-auto mb-6" />
              <h1 class="text-center mb-6 text-md font-medium leading-tight text-primary">Analyzing</h1>
              <h2
                class="text-center text-sm"
              >Your manuscripts and documents will be analyzed by independent team.</h2>
            </div>
          </div>
        </div>
        <div class="w-full pl-5 lg:w-2/4">
          <div class="bg-globe1 bg-cover mr-10 mt-10">
            <img src="../assets/editing_Service.png" alt="catra" class="max-w-full mx-auto" />
          </div>
        </div>
        <div class="w-full self-center pl-10 lg:w-1/4">
          <div
            class="transition duration-300 ease-in-out hover:bg-lemon block max-w-[350px] rounded-[20px] shadow-lg bg-bodas mr-10 mb-10 lg:mb-20 lg:w-[248px] lg:h-[300px]"
          >
            <div class="pt-14">
              <img src="../assets/payment.png" alt="catra" class="max-w-full mx-auto mb-6" />
              <h1 class="text-center mb-6 text-md font-medium leading-tight text-primary">Payment</h1>
              <h2
                class="text-center text-sm"
              >Manuscripts and documents are sent by paying 50% DP. Full payment can be paid after the work is completed.</h2>
            </div>
          </div>
          <div
            class="transition duration-300 ease-in-out hover:bg-lemon block max-w-[350px] rounded-[20px] shadow-lg bg-bodas mr-10 lg:mb-20 lg:w-[248px] lg:h-[300px]"
          >
            <div class="pt-14">
              <img src="../assets/processing.png" alt="catra" class="max-w-full mx-auto mb-6" />
              <h1 class="text-center mb-6 text-md font-medium leading-tight text-primary">Processing</h1>
              <h2
                class="mx-2 text-center text-sm"
              >Manuscripts and documents are processed in some stages; editing, translation process, proofreading, templates and will accept certificate.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import "@/assets/tailwind.css";

export default {};
</script>
      
      <style>
</style>