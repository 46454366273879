<template>
  <div id="service">
    <div class="pt-[50px] lg:pt-[150px] 2xl:px-60 flex-container">
      <div class="flex flex-wrap">
        <div class="w-full self-end px-4 lg:w-1/2">
          <div class="bg-globe1 bg-cover mt-10">
            <img src="../assets/our_service.png" alt="catra" class="max-w-full mx-auto" />
          </div>
        </div>
        <div class="w-full pl-5 lg:w-1/2">
          <h1 class="mb-10 text-lg font-bold">
            Our
            <span class="text-primary">Services</span>
          </h1>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class>
                <h1
                  class="text-bodas bg-second rounded-full px-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >1</h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">DOAJ Indexing Assistance</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >We conduct excellent services in assisting and managing journal to be indexed</h2>
              </div>
            </div>
          </div>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class>
                <h1
                  class="text-bodas bg-second rounded-full px-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >2</h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Research Program and Study Center</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >we provide technical assistance as well as research study center related to the latest research that can attract the interest of readers.</h2>
              </div>
            </div>
          </div>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class>
                <h1
                  class="text-bodas bg-second rounded-full px-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >3</h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Proofreading and Paraphase</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >We provide proofreading and paraphrasing services for authors of academics and scientific papers for publication and examination in all fields of study.</h2>
              </div>
            </div>
          </div>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class>
                <h1
                  class="text-bodas bg-second rounded-full px-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >4</h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Develop Open Journal System</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >We provide remarkable assistances to help managing OJS as well as its systemic process.</h2>
              </div>
            </div>
          </div>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class>
                <h1
                  class="text-bodas bg-second rounded-full px-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >5</h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Data Processing</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >we provide multidisciplinary expertise in helping data analytical processing for reputable research.</h2>
              </div>
            </div>
          </div>
          <div class="bg-primary mb-5 mr-5 shadow-lg rounded-full lg:h-[70px] lg:w-[515px]">
            <div class="grid grid-cols-12">
              <div class>
                <h1
                  class="text-bodas bg-second rounded-full px-2 text-center lg:h-[70px] lg:w-[70px] lg:px-4 lg:text-[45px]"
                >6</h1>
              </div>
              <div class="col-span-11 ml-3 mt-1 lg:col-span-11 lg:ml-8 lg:mt-1">
                <h1 class="text-base font-bold text-abhu">Turnitin and IThenticate</h1>
                <h2
                  class="text-sm font-normal text-abhu"
                >We do plagiarism checking work in accordance with international journal standards and DIKTI</h2>
              </div>
            </div>
          </div>
          <!-- <a class="text-abhu font-bold bg-primary py-5 pr-3 rounded-full">
            <span class="font-bold bg-second text-bodas py-5 px-6 rounded-full">1</span> DOAJ Indexing Assistance
          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import "@/assets/tailwind.css";

export default {};
</script>
  
  <style>
</style>